.logos {
  $m-left: 40px;
  $m-top: 38px;
  overflow: hidden;

  &__wrap {
    display: flex;
    justify-content: center;
    margin: ($m-top / 2 * -1) ($m-left / 2 * -1);
  }

  &__item {
    padding: ($m-top / 2) ($m-left / 2);
  }

  &__logo {
    height: 45px;
    width: auto;
    max-width: none;
  }
}
